import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getUserDetails } from "../actions/userActions.js";
import {
  listPostDetails,
  deletePost,
  updatePost,
} from "../actions/postActions";
import { POST_UPDATE_RESET } from "../constants/postConstants";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { useAuthValue } from "../AuthContext";

const PostsScreen = ({ history }) => {
  const { currentUser } = useAuthValue();
  const postDetails = useSelector((state) => state.postDetails);
  const { error: postError, loading: postLoading, post } = postDetails;
  const [postTitle, setPostTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const [mediaType, setMediaType] = useState("");

  let keyword = history.location.search;

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const postUpdate = useSelector((state) => state.postUpdate);
  const { success } = postUpdate;

  let post_pathname = history.location.pathname;
  let post_id = post_pathname.substring(11, post_pathname.length - 1);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!currentUser) {
      history.push("/");
    } else {
      if (!user || currentUser.email !== user.email) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails(currentUser.uid));
      } else if (success) {
        dispatch({ type: POST_UPDATE_RESET });
        history.push("/update_posts/");
      } else if (post.id === parseInt(post_id)) {
        setMediaType(post.media_type);
        setPostTitle(post.title);
        setPostContent(post.content);
      } else {
        dispatch(listPostDetails(post_id));

        setMediaType(post.media_type);
        setPostTitle(post.title);
        setPostContent(post.content);
      }
    }
  }, [dispatch, history, keyword, user, success, post]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updatePost({
        _id: user.id,
        id: post.id,
        media_type: mediaType,
        post_title: postTitle,
        post_content: postContent,
      })
    );
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(deletePost(post.id));
  };

  // if (isLoading) {
  //   return <div>Loading ...</div>;
  // }

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <div class="post-content non-media">
            <h2>Edit Post</h2>
            <div class="media-body">
              <div class="article-metadata">
                <small class="non-media text-muted">{post.date_posted}</small>
              </div>
              {post.media_type == "VIDEO" ? (
                <iframe
                  width="560"
                  height="315"
                  src={post.content}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              ) : (
                <div
                  width="560"
                  height="315"
                  dangerouslySetInnerHTML={{ __html: post.content }}
                ></div>
              )}
              <div>
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="postTitle">
                    <Form.Label>Media Type</Form.Label>
                    <Form.Select
                      size="lg"
                      value={mediaType}
                      onChange={(e) => setMediaType(e.target.value)}
                    >
                      <option value="VIDEO">Video</option>
                      <option value="MUSIC">Music</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="postTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      required
                      type="postTitle"
                      placeholder="Add Title"
                      value={postTitle}
                      onChange={(e) => setPostTitle(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="postContent">
                    <Form.Label>Content</Form.Label>
                    <Form.Control
                      required
                      type="postContent"
                      placeholder="Add Content"
                      value={postContent}
                      as="textarea"
                      rows={5}
                      onChange={(e) => setPostContent(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                  <Button
                    type="submit"
                    variant="danger"
                    onClick={deleteHandler}
                  >
                    Delete
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PostsScreen;
