import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getUserDetails } from "../actions/userActions.js";
import Post from "../components/Post";
import { listPosts } from "../actions/postActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import Paginate from "../components/Paginate";
import { useAuthValue } from "../AuthContext";

const PostsScreen = ({ history }) => {
  const { currentUser } = useAuthValue();
  const postList = useSelector((state) => state.postList);
  const {
    error: postError,
    loading: postLoading,
    posts,
    page,
    pages,
  } = postList;

  let keyword = history.location.search;

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!currentUser) {
      history.push("/");
    } else {
      if (!user || success || currentUser.email !== user.email) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails(currentUser.uid));
      } else {
        dispatch(listPosts(keyword));
      }
    }
  }, [dispatch, history, keyword, user, success]);

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <div class="post-content non-media">
            <h2>Posts:</h2>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <article class="content-section">
                {posts.map((post) => (
                  <Post post={post} />
                ))}
              </article>
            )}
          </div>
        </Col>
      </Row>
      <Paginate page={page} pages={pages} keyword={keyword} />
    </div>
  );
};

export default PostsScreen;
