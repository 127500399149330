import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getUserDetails, updateUserContent } from "../actions/userActions.js";
import { USER_UPDATE_CONTENT_RESET } from "../constants/userConstants";
import { useAuthValue } from "../AuthContext";

const ContentScreen = ({ history }) => {
  const { currentUser } = useAuthValue();
  const [featureAtitle, setFeatureAtitle] = useState("");
  const [featureAcontent, setFeatureAcontent] = useState("");
  const [featureBtitle, setFeatureBtitle] = useState("");
  const [featureBcontent, setFeatureBcontent] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userUpdateContent = useSelector((state) => state.userUpdateContent);
  const { success } = userUpdateContent;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!currentUser) {
      history.push("/");
    } else {
      if (!user || success || currentUser.email !== user.email) {
        dispatch({ type: USER_UPDATE_CONTENT_RESET });
        dispatch(getUserDetails(currentUser.uid));
      } else {
        setFeatureAtitle(user.featured_a_title);
        setFeatureAcontent(user.featured_a);
        setFeatureBtitle(user.featured_b_title);
        setFeatureBcontent(user.featured_b);
      }
    }
  }, [dispatch, history, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUserContent({
        featured_a_title: featureAtitle,
        featured_a: featureAcontent,
        featured_b_title: featureBtitle,
        featured_b: featureBcontent,
      })
    );
  };

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    console.log("uploadFileHandler");
  };

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="featureAtitle">
              <Form.Label>Feature A Title</Form.Label>
              <Form.Control
                required
                type="featureAtitle"
                placeholder="Add Title"
                value={featureAtitle}
                onChange={(e) => setFeatureAtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="featureAcontent">
              <Form.Label>Feature A Content</Form.Label>
              <Form.Control
                required
                type="featureAcontent"
                placeholder="Add Content"
                value={featureAcontent}
                as="textarea"
                rows={5}
                onChange={(e) => setFeatureAcontent(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="featureBtitle">
              <Form.Label>Feature B Title</Form.Label>
              <Form.Control
                required
                type="featureBtitle"
                placeholder="Add Title"
                value={featureBtitle}
                onChange={(e) => setFeatureBtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="featureBcontent">
              <Form.Label>Feature B Content</Form.Label>
              <Form.Control
                required
                type="featureBcontent"
                placeholder="Add Content"
                value={featureBcontent}
                as="textarea"
                rows={5}
                onChange={(e) => setFeatureBcontent(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ContentScreen;
