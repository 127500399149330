import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const Post = ({ post }) => {
  return (
    <div class="media-body">
      <div class="article-metadata">
        <small class="non-media text-muted">
          <Link to={"/edit_post/" + post.id + "/"}> {post.date_posted} </Link>
        </small>
      </div>
      {post.media_type == "VIDEO" ? (
        <iframe
          width="560"
          height="315"
          src={post.content}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
        ></iframe>
      ) : (
        <div
          width="560"
          height="315"
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
      )}
    </div>
  );
};

export default Post;
