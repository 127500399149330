import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

function UserBanner() {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { error, loading, users } = userList;

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  return (
    <header className="sticky-top">
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Row>
            {users.map((user) => (
              <Col className="text-center py-3 user-banner">
                <Link to={"/profile/" + user.username}>{user.username}</Link>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </header>
  );
}

export default UserBanner;
