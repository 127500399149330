import { Container } from "react-bootstrap";
import { HashRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProfileScreen from "./screens/ProfileScreen";
import UpdateProfileScreen from "./screens/UpdateProfileScreen";
import ContentScreen from "./screens/ContentScreen";
import DetailsScreen from "./screens/DetailsScreen";
import InterestsScreen from "./screens/InterestsScreen";
import CreatePostsScreen from "./screens/CreatePostScreen";
import PostsScreen from "./screens/PostsScreen";
import EditPostScreen from "./screens/EditPostScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import { AuthProvider } from "./AuthContext";
import axios from "axios";

// if (window.location.origin === "http://localhost:3000") {
//   axios.defaults.baseURL = "http://127.0.0.1/backend/";
// } else {
//   axios.defaults.baseURL = window.location.origin;
// }

function App(props) {
  return (
    <Router>
      <AuthProvider>
        <Route
          path={[
            "/login",
            "/register",
            "/profile/:id",
            "/update_profile",
            "/update_content",
            "/update_personal",
            "/update_details",
            "/update_interests",
            "/create_post",
            "/update_posts",
            "/edit_post",
          ]}
          component={Header}
        />
        <main className="py-3">
          <Container id="main">
            <Route path="/login" component={LoginScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/profile/:id" component={ProfileScreen} />
            <Route path="/update_profile" component={UpdateProfileScreen} />
            <Route path="/update_content" component={ContentScreen} />
            <Route path="/update_personal" component={DetailsScreen} />
            <Route path="/update_details" component={DetailsScreen} />
            <Route path="/update_interests" component={InterestsScreen} />
            <Route path="/create_post" component={CreatePostsScreen} />
            <Route path="/update_posts" component={PostsScreen} />
            <Route path="/edit_post" component={EditPostScreen} />
          </Container>
        </main>

        <Route path="/" component={HomeScreen} exact />

        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
