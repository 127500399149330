import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Image } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Post from "../components/Post";
import { listPosts } from "../actions/postActions";
import { getUserDetails } from "../actions/userActions.js";
import Paginate from "../components/Paginate";
import background from "../stars.gif";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

function ProfileScreen({ history }) {
  const postList = useSelector((state) => state.postList);
  const { error, loading, posts, page, pages } = postList;

  let keyword = history.location.search;
  let path_username = history.location.pathname.split("/")[2];

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");

  const [featureAtitle, setFeatureAtitle] = useState("");
  const [featureAcontent, setFeatureAcontent] = useState("");
  const [featureBtitle, setFeatureBtitle] = useState("");
  const [featureBcontent, setFeatureBcontent] = useState("");

  const [general, setGeneral] = useState("");
  const [music, setMusic] = useState("");
  const [movie, setMovie] = useState("");
  const [television, setTelevision] = useState("");
  const [books, setBooks] = useState("");
  const [heroes, setHeroes] = useState("");

  const [relationshipStatus, setRelationshipStatus] = useState("");
  const [hereFor, setHereFor] = useState("");
  const [zodiacSign, setZodiacSign] = useState("");
  const [education, setEducation] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error: userError, loading: userLoad, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;

    if (!user.id) {
      console.log("dispatch: getUserDetails");
      dispatch(getUserDetails(path_username));
    } else {
      console.log("dispatch: listPosts");
      dispatch(listPosts(keyword));
      setUsername(user.username);
      setImage(user.image);
      setName(user.name);
      setEmail(user.email);
      setAge(user.age);
      setState(user.state);
      setCity(user.city);
      setCountry(user.country);
      var options = {
        // weekday: "short",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        // second: "2-digit",
      };
      var date_obj = new Date(user.last_login);
      date_obj = date_obj.toLocaleDateString("en-US", options).toString();
      console.log("date_obj", date_obj);
      setDate(date_obj);

      setFeatureAtitle(user.featured_a_title);
      setFeatureAcontent(user.featured_a);
      setFeatureBtitle(user.featured_b_title);
      setFeatureBcontent(user.featured_b);

      setGeneral(user.general);
      setMusic(user.music);
      setMovie(user.movie);
      setTelevision(user.television);
      setBooks(user.books);
      setHeroes(user.heroes);

      setRelationshipStatus(user.relationship_status);
      setHereFor(user.here_for);
      setZodiacSign(user.zodiac_sign);
      setEducation(user.education);
    }
  }, [dispatch, keyword, user, path_username]);

  return (
    <div className="content">
      <div className="flex-container" id="left">
        <div className="media youtube" id="media-youtube">
          <div class="vplayer">
            <h2>{featureAtitle}:</h2>
            <div
              width="560"
              height="315"
              dangerouslySetInnerHTML={{ __html: featureAcontent }}
            ></div>
          </div>
        </div>
        <div className="profile-data">
          <div className="top">
            <h2>{username}</h2>
          </div>
          <div className="middle">
            <div className="profile-pic">
              {window.location.origin === "https://hotelchatroom.com" ? (
                <img
                  id="profile-pic"
                  src={"https://hotelchatroom.com" + user.image}
                  alt="hotel chat room logo"
                />
              ) : (
                <img
                  id="profile-pic"
                  src={"http://localhost:8000" + user.image}
                  alt="hotel chat room logo"
                />
              )}
            </div>
            <div className="profile-info">
              <br />
              {user.name}
              <br />
              {user.age + " years old"}
              <br />
              {user.city + ", " + user.state}
              <br />
              {user.country}
              <br />
              <br />
              Last Login: <br />
              {date}
              <br />
            </div>
          </div>
          <div className="bottom">
            <div>
              <b>Mood:</b>
              <span>neutral</span>{" "}
              {window.location.origin === "https://hotelchatroom.com" ? (
                <img
                  src={"https://hotelchatroom.com" + user.mood}
                  alt="Mood Image"
                />
              ) : (
                <img
                  src={"http://localhost:8000" + user.mood}
                  alt="Mood Image"
                />
              )}
            </div>
          </div>
          <div className="bottom">
            <div>
              View My:
              <a href="{% url 'chatroom_app-posts' %}"> Posts </a>|
              <a href="{% url 'chatroom_app-video' %}"> Videos </a>|
              <a href="{% url 'chatroom_app-music' %}"> Music</a>
            </div>
          </div>
        </div>
        <div className="contacting">
          <div class="top">
            <h2>Contacting Hotel Room</h2>
          </div>
          <div class="contact-icon-div">
            <div class="contact-icons">
              <a
                href="http://messaging.myspace.com/index.cfm?fuseaction=invite.addfriend_verify&amp;friendID=495630524"
                target="_blank"
              >
                <img
                  src="chatroom_app/img/friend_1.gif"
                  border="0"
                  align="middle"
                  alt=""
                />
              </a>
              <a href="#">
                <img
                  src="chatroom_app/img/forward_1.gif"
                  border="0"
                  align="middle"
                  alt=""
                />
              </a>
              <a href="#">
                <img
                  src="chatroom_app/img/mail_1.gif"
                  border="0"
                  align="middle"
                  alt=""
                />
              </a>
              <a href="#">
                <img
                  src="chatroom_app/img/favorite_1.gif"
                  border="0"
                  align="middle"
                  alt=""
                />
              </a>
              <img
                src="chatroom_app/img/imskype_1.gif"
                id="ctl00_cpMain_ctl01_UserContactLinks1_messageFriendLinkImg"
                border="0"
                align="middle"
                alt=""
              />
              <a href="#">
                <img
                  src="chatroom_app/img/block_1.gif"
                  border="0"
                  align="middle"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="media twitch" id="media-twitch">
          <div class="vplayer">
            <h2>{featureBtitle}:</h2>
            <div
              width="560"
              height="315"
              dangerouslySetInnerHTML={{ __html: featureBcontent }}
            ></div>
            --
          </div>
        </div>
        <div className="spotify">
          <div id="mini">
            <h2>Music</h2>
            <iframe
              src="https://open.spotify.com/embed/album/0hRuwQPmsXvKKcK0vcEWrL?utm_source=generator"
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
        </div>
        <div className="media" id="media-content">
          <div class="top">
            <h2>Posts:</h2>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <article class="content-section">
              {posts.map((post) => (
                <Post post={post} />
              ))}
            </article>
          )}
        </div>
        <Paginate
          className="media"
          page={page}
          pages={pages}
          keyword={keyword}
        />
        <div class="intrests">
          <div class="top">
            <h2>{username}'s interests</h2>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>General</b>
            </div>
            <div class="right-textbox">{general}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Music</b>
            </div>
            <div class="right-textbox">{music}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Movies</b>
            </div>
            <div class="right-textbox">{movie}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Television</b>
            </div>
            <div class="right-textbox">{television}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Books</b>
            </div>
            <div class="right-textbox">{books}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Heroes</b>
            </div>
            <div class="right-textbox">{heroes}</div>
          </div>
        </div>
        <div class="details">
          <div class="top">
            <h2>{username}'s details</h2>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Status:</b>
            </div>
            <div class="right-textbox">{relationshipStatus}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Here for:</b>
            </div>
            <div class="right-textbox">{hereFor}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Zodiac Sign:</b>
            </div>
            <div class="right-textbox">{zodiacSign}</div>
          </div>
          <div class="middle" id="intrests">
            <div class="left-textbox">
              <b>Education:</b>
            </div>
            <div class="right-textbox">{education}</div>
          </div>
        </div>
      </div>
      <div className="flex-container" id="right">
        <div className="youtube non-media">
          <div class="vplayer">
            <h2>{featureAtitle}:</h2>
            <div
              width="560"
              height="315"
              dangerouslySetInnerHTML={{ __html: featureAcontent }}
            ></div>
          </div>
        </div>
        <div className="non-media twitch" id="non-media-twitch">
          <div class="vplayer">
            <h2>{featureBtitle}:</h2>
            <div
              width="560"
              height="315"
              dangerouslySetInnerHTML={{ __html: featureBcontent }}
            ></div>
            --
          </div>
        </div>
        <div className="post-content non-media">
          <h2>Posts:</h2>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <article class="content-section">
              {posts.map((post) => (
                <Post post={post} />
              ))}
            </article>
          )}
        </div>
        <Paginate
          className="non-media"
          page={page}
          pages={pages}
          keyword={keyword}
        />
        <div className="friends non-media">
          <div class="top">
            <h2>hotelroom's Friend Space (Top5)</h2>
          </div>
          <div class="top" id="friends-text">
            <b>hotelroom has 5 friends</b>
          </div>
          <div class="middle flex-container" id="friends-pics">
            <div class="friends-profile-pic">
              &nbsp;<a href="#">Tom</a>&nbsp;
              <div class="friends-img">
                <a href="#">
                  <img
                    src="./chatroom_app/profile_pics/tom.jpg"
                    alt=" tom's profile pic"
                  />
                </a>
              </div>
            </div>
            <div class="friends-profile-pic">
              &nbsp;<a href="#">Borto</a>&nbsp;
              <div class="friends-img">
                <a href="#">
                  <img
                    src="./chatroom_app/profile_pics/borto.jpg"
                    alt="bortos's profile pic"
                  />
                </a>
              </div>
            </div>
            <div class="friends-profile-pic">
              &nbsp;<a href="#">Nate Savage</a>&nbsp;
              <div class="friends-img">
                <a href="#">
                  <img
                    src="./chatroom_app/profile_pics/nateSavage.jpg"
                    alt="nate savage's profile pic"
                  />
                </a>
              </div>
            </div>
            <div class="friends-profile-pic">
              &nbsp;<a href="#">Blake</a>&nbsp;
              <div class="friends-img">
                <a href="#">
                  <img
                    src="./chatroom_app/profile_pics/blakeCUTout.jpg"
                    alt="blake's profile pic"
                  />
                </a>
              </div>
            </div>
            <div class="friends-profile-pic">
              &nbsp;<a href="#">Mikey</a>&nbsp;
              <div class="friends-img">
                <a href="#">
                  <img
                    src="./chatroom_app/profile_pics/mikey.jpg"
                    alt="mikey's profile pic"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="bottom" id="friends-bottom">
            <div>
              <a href="#">View hotelroom's Friends: All | Online | New </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileScreen;
