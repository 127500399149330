import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { getUserDetails, updateUserContent } from "../actions/userActions.js";
import { USER_UPDATE_CONTENT_RESET } from "../constants/userConstants";
import { useAuthValue } from "../AuthContext";

const InterestsScreen = ({ history }) => {
  const { currentUser } = useAuthValue();
  const [featureAtitle, setFeatureAtitle] = useState("");
  const [featureAcontent, setFeatureAcontent] = useState("");
  const [featureBtitle, setFeatureBtitle] = useState("");
  const [featureBcontent, setFeatureBcontent] = useState("");

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userUpdateContent = useSelector((state) => state.userUpdateContent);
  const { success } = userUpdateContent;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!currentUser) {
      history.push("/");
    } else {
      if (!user || success || currentUser.email !== user.email) {
        dispatch({ type: USER_UPDATE_CONTENT_RESET });
        dispatch(getUserDetails(currentUser.uid));
      } else {
        setFeatureAtitle(user.featured_a_title);
        setFeatureAcontent(user.featured_a);
        setFeatureBtitle(user.featured_b_title);
        setFeatureBcontent(user.featured_b);
      }
    }
  }, [dispatch, history, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUserContent({
        featured_a_title: featureAtitle,
        featured_a: featureAcontent,
        featured_b_title: featureBtitle,
        featured_b: featureBcontent,
      })
    );
  };

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    console.log("uploadFileHandler");
  };

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="general">
              <Form.Label>General</Form.Label>
              <Form.Control
                required
                type="general"
                placeholder="Add general interests"
                value={featureAtitle}
                onChange={(e) => setFeatureAtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="music">
              <Form.Label>Music</Form.Label>
              <Form.Control
                required
                type="music"
                placeholder="Add music interests"
                value={featureAtitle}
                onChange={(e) => setFeatureAtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="television">
              <Form.Label>Television</Form.Label>
              <Form.Control
                required
                type="television"
                placeholder="Add television interests"
                value={featureAtitle}
                onChange={(e) => setFeatureAtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="books">
              <Form.Label>Books</Form.Label>
              <Form.Control
                required
                type="books"
                placeholder="Add favorite book"
                value={featureAtitle}
                onChange={(e) => setFeatureAtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="heroes">
              <Form.Label>Heroes</Form.Label>
              <Form.Control
                required
                type="heroes"
                placeholder="Add favorite hero"
                value={featureAtitle}
                onChange={(e) => setFeatureAtitle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default InterestsScreen;
