import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
const Paginate = ({ page, pages, keyword = "", isAdmin = false }) => {
  console.log("PAGE: ", page);
  if (keyword) {
    keyword = keyword.split("?keyword=")[1].split("&")[0];
  }

  return (
    pages > 1 && (
      <Pagination>
        <LinkContainer key={1} to={`?keyword=${keyword}&page=${1}`}>
          <Pagination.First />
        </LinkContainer>
        {[...Array(pages).keys()].map((x) => (
          <LinkContainer key={x + 1} to={`?keyword=${keyword}&page=${x + 1}`}>
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))}
        <LinkContainer key={pages} to={`?keyword=${keyword}&page=${pages}`}>
          <Pagination.Last />
        </LinkContainer>
      </Pagination>
    )
  );
};

export default Paginate;
