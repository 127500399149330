import React, { useState, useEffect } from "react";
import { Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { getUserDetails, updateUserProfile } from "../actions/userActions.js";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

import axios from "axios";
import { useAuthValue } from "../AuthContext";

import Form from "../components/Form";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, logout } from "../actions/userActions";

const LoginScreen = ({ history }) => {
  const { currentUser } = useAuthValue();

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const [uploading, setUploading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  document.body.style.backgroundImage = `url(${background})`;
  useEffect(() => {
    // if (!currentUser) {
    //   history.push("/");
    // } else {
    //   if (!user || success || currentUser.email !== user.email) {
    //     dispatch({ type: USER_UPDATE_PROFILE_RESET });
    //     dispatch(getUserDetails(currentUser.uid));
    //   } else {
    //     setImage(user.image);
    //     setUsername(user.username);
    //     setFirstName(user.first_name);
    //     setLastName(user.last_name);
    //     setEmail(user.email);
    //     setAge(user.age);
    //     setState(user.state);
    //     setCity(user.city);
    //     setCountry(user.country);
    //   }
    // }
  }, [dispatch, history, user, success]);

  const signIn = () => {
    console.log("about time");
    // const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        console.log("response:", response);
        // The signed-in user info.
        const user = response.user;
        console.log("user: ", user);
        return user;
      })
      .then((response) => {
        let token = response.accessToken;
        const uid = response.uid;
        const name = response.displayName;
        let email = response.email;
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            console.log("header.js token", idToken);
            dispatch(login(name, uid, email, idToken));
          })
          .then(function () {
            const profile_link = "/profile/" + user.username;
            history.push(profile_link);
          })
          .catch(function (error) {
            // Handle error
          });

        // const lastLogin = result.metadata.lastSignInTime;

        console.log("header.js uid", uid);
        // dispatch(login(name, uid, email, token));
      })
      .catch((error) => {
        console.log("error: ", error);

        if (error.code === "auth/wrong-password") {
          toast.error("Please check the Password");
        }
        if (error.code === "auth/user-not-found") {
          toast.error("Please check the Email");
        }
        if (error.code === "auth/invalid-email") {
          toast.error("Invalid Email");
        }
      });
  };

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <Form
            title="Login"
            setEmail={setEmail}
            setPassword={setPassword}
            handleAction={() => signIn()}
            oppo="register"
          />
          <ToastContainer />
        </Col>
      </Row>
    </div>
  );
};

export default LoginScreen;
