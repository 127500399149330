import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const Message = ({ variant, children }) => {
  const [showSuccess, setShowSuccess] = useState(true);
  const [showDanger, setShowDanger] = useState(true);
  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (variant === "success") {
    if (showSuccess) {
      return (
        <Alert variant={variant} onClose={() => setShowSuccess(false)}>
          {children}
        </Alert>
      );
    } else {
      return null;
    }
  }

  if (variant === "danger") {
    if (showDanger) {
      return (
        <Alert variant={variant}>
          <div>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShowDanger(false)}
                variant="outline-light"
                size="sm"
              >
                Close
              </Button>
            </div>
            <div className="d-flex justify-content-center">{children}</div>
          </div>
        </Alert>
      );
    }
    return null;
  }
};

export default Message;
