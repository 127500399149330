import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getUserDetails, updateUserPersonal } from "../actions/userActions.js";
import { USER_UPDATE_PERSONAL_RESET } from "../constants/userConstants";
import { useAuthValue } from "../AuthContext";

const DetailsScreen = ({ history }) => {
  const { currentUser } = useAuthValue();
  const [general, setGeneral] = useState("");
  const [music, setMusic] = useState("");
  const [movie, setMovie] = useState("");
  const [television, setTelevision] = useState("");
  const [books, setBooks] = useState("");
  const [heroes, setHeroes] = useState("");

  const [relationshipStatus, setRelationshipStatus] = useState("");
  const [hereFor, setHereFor] = useState("");
  const [zodiacSign, setZodiacSign] = useState("");
  const [education, setEducation] = useState("");

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userUpdatePersonal = useSelector((state) => state.userUpdatePersonal);
  const { success } = userUpdatePersonal;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!currentUser) {
      history.push("/");
    } else {
      if (!user || success || currentUser.email !== user.email) {
        console.log("success", success);
        dispatch({ type: USER_UPDATE_PERSONAL_RESET });
        dispatch(getUserDetails(currentUser.uid));
      } else {
        setGeneral(user.general);
        setMusic(user.music);
        setMovie(user.movie);
        setTelevision(user.television);
        setBooks(user.books);
        setHeroes(user.heroes);

        setRelationshipStatus(user.relationship_status);
        setHereFor(user.here_for);
        setZodiacSign(user.zodiac_sign);
        setEducation(user.education);
      }
    }
  }, [dispatch, history, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUserPersonal({
        general: general,
        music: music,
        movie: movie,
        television: television,
        books: books,
        heroes: heroes,

        relationship_status: relationshipStatus,
        here_for: hereFor,
        zodiac_sign: zodiacSign,
        education: education,
      })
    );
  };

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    console.log("uploadFileHandler");
  };

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="general">
              <Form.Label>General</Form.Label>
              <Form.Control
                required
                type="general"
                placeholder="Add general interests"
                value={general}
                onChange={(e) => setGeneral(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="music">
              <Form.Label>Music</Form.Label>
              <Form.Control
                required
                type="music"
                placeholder="Add music interests"
                value={music}
                onChange={(e) => setMusic(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="movie">
              <Form.Label>Movie</Form.Label>
              <Form.Control
                required
                type="movie"
                placeholder="Add movie interests"
                value={movie}
                onChange={(e) => setMovie(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="television">
              <Form.Label>Television</Form.Label>
              <Form.Control
                required
                type="television"
                placeholder="Add television interests"
                value={television}
                onChange={(e) => setTelevision(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="books">
              <Form.Label>Books</Form.Label>
              <Form.Control
                required
                type="books"
                placeholder="Add favorite book"
                value={books}
                onChange={(e) => setBooks(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="heroes">
              <Form.Label>Heroes</Form.Label>
              <Form.Control
                required
                type="heroes"
                placeholder="Add favorite hero"
                value={heroes}
                onChange={(e) => setHeroes(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="relationshipStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                required
                type="relationshipStatus"
                placeholder="Relationship Status"
                value={relationshipStatus}
                onChange={(e) => setRelationshipStatus(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="hereFor">
              <Form.Label>Here for</Form.Label>
              <Form.Control
                required
                type="hereFor"
                placeholder="What are you here for?"
                value={hereFor}
                onChange={(e) => setHereFor(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="zodiacSign">
              <Form.Label>Zodiac Sign</Form.Label>
              <Form.Control
                required
                type="zodiacSign"
                placeholder="Add Zodiac Sign"
                value={zodiacSign}
                onChange={(e) => setZodiacSign(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="education">
              <Form.Label>Education</Form.Label>
              <Form.Control
                required
                type="education"
                placeholder="Add Education"
                value={education}
                onChange={(e) => setEducation(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default DetailsScreen;
