import React, { useState, useEffect } from "react";
import { Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { getUserDetails, updateUserProfile } from "../actions/userActions.js";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

import axios from "axios";
import { useAuthValue } from "../AuthContext";

import Form from "../components/Form";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, logout } from "../actions/userActions";

const RegisterScreen = ({ history }) => {
  const { currentUser } = useAuthValue();

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const [uploading, setUploading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  document.body.style.backgroundImage = `url(${background})`;
  useEffect(() => {
    // if (!currentUser) {
    //   history.push("/");
    // } else {
    //   if (!user || success || currentUser.email !== user.email) {
    //     dispatch({ type: USER_UPDATE_PROFILE_RESET });
    //     dispatch(getUserDetails(currentUser.uid));
    //   } else {
    //     setImage(user.image);
    //     setUsername(user.username);
    //     setFirstName(user.first_name);
    //     setLastName(user.last_name);
    //     setEmail(user.email);
    //     setAge(user.age);
    //     setState(user.state);
    //     setCity(user.city);
    //     setCountry(user.country);
    //   }
    // }
  }, [dispatch, history, user, success]);

  const register = () => {
    // const provider = new GoogleAuthProvider();
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        // The signed-in user info.
        console.log("Register - response: ", response);
        const user = response.user;
        return user;
      })
      .then((response) => {
        let token = response.accessToken;
        const uid = response.uid;
        const name = response.displayName;
        let email = response.email;
        // const lastLogin = result.metadata.lastSignInTime;

        console.log("header.js token", token);
        console.log("header.js uid", uid);
        dispatch(login(name, uid, email, token));
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          toast.error("Email Already in Use");
        }
        if (error.code === "auth/weak-password") {
          toast.error("Password game WEAK!");
        }
      });
  };

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <Form
            title="Register"
            setEmail={setEmail}
            setPassword={setPassword}
            handleAction={() => register()}
            oppo="register"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RegisterScreen;
