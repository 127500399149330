import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import background from "../stars.gif";
import { Link } from "react-router-dom";
import UserBanner from "../components/UserBanner";

function HomeScreen() {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
  }, []);

  return (
    <div className="home-logo">
      <UserBanner />
      <Link to="/">
        <Image src="/hotelroomlogo.gif" alt="hotel chat room logo" />
      </Link>
    </div>
  );
}

export default HomeScreen;
