import React from "react";
import { Navbar, Nav, NavDropdown, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../firebaseConfig";
import { login, logout } from "../actions/userActions";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuthValue } from "../AuthContext";
import SpeedDialComp from "../components/SpeedDialComp";

function Header() {
  const { currentUser } = useAuthValue();
  const userDetails = useSelector((state) => state.userDetails);
  const { error: userError, loading: userLoad, user } = userDetails;
  const dispatch = useDispatch();

  const signIn = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        // ...
        console.log("big dog", user);
        console.log("user metadata", user.metadata.lastSignInTime);
        // dispatch(login("dj", "test@gmail.com", "test123"));
        return user;
        // email = token;
        // password = token;
      })
      .then((result) => {
        let token = result.accessToken;
        const uid = result.uid;
        const name = result.displayName;
        let email = result.email;
        // const lastLogin = result.metadata.lastSignInTime;

        console.log("header.js token", token);
        dispatch(login(name, uid, email, token));
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    // console.log("dog", token);
  };

  const logoutHandler = () => {
    auth.signOut();
    console.log("dispatch: logout");
    dispatch(logout());
    console.log("logged out.............................");
  };

  return (
    <header className="sticky-top">
      <div className="navbar">
        <Row>
          <Col className="text-center">
            <Navbar.Brand href="#/" className="logo">
              <Image src="/hotelroomlogo.gif" alt="hotel chat room logo" />
            </Navbar.Brand>
          </Col>
        </Row>
      </div>
      <Navbar variant="dark" expand="lg">
        <Row id="nav-row">
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}

          <Navbar id="basic-navbar-nav">
            <Col className="text-center">
              <Nav className="me-auto">
                <Nav.Link href="#/">Home</Nav.Link>
                <Nav.Link
                  href="https://hotelroom.s3.us-west-2.amazonaws.com/hotel_epk.pdf"
                  target="_blank"
                >
                  EPK
                </Nav.Link>
                <NavDropdown
                  title="Social Media"
                  id="basic-nav-dropdown"
                  class="dropbtn"
                >
                  <NavDropdown.Item
                    href="https://www.instagram.com/hotelroom00/?utm_source=ig_embed"
                    target="_blank"
                  >
                    Instagram
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://twitter.com/tellygot1"
                    target="_blank"
                  >
                    Twitter
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://linktr.ee/hotelroom00"
                    target="_blank"
                  >
                    Link Tree
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Music"
                  id="basic-nav-dropdown"
                  class="dropbtn"
                >
                  <NavDropdown.Item
                    href="https://open.spotify.com/artist/5wTm7srBSHKjGIDM51xMuU"
                    target="_blank"
                  >
                    Spotify
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://music.apple.com/us/artist/hotelroom/1452107586"
                    target="_blank"
                  >
                    Apple Music
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank">
                    Sound Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="https://www.youtube.com/channel/UCb3teWELCrrRMV0ho8rLFWg"
                    target="_blank"
                  >
                    Youtube Channel
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="https://hotelroom00.bigcartel.com/"
                  target="_blank"
                >
                  Merch
                </Nav.Link>
              </Nav>
            </Col>

            <Col className="non-media text-center">
              {currentUser ? (
                <Nav className="justify-content-end">
                  <NavDropdown
                    title="Profile"
                    id="basic-nav-dropdown"
                    class="dropbtn"
                  >
                    <NavDropdown.Item href={"#/profile/" + user.username}>
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/update_profile">
                      Profile Info
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/update_personal">
                      Personal
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Content"
                    id="basic-nav-dropdown"
                    class="dropbtn"
                  >
                    <NavDropdown.Item href="#/update_content">
                      Featured Content
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/create_post">
                      New Post
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/update_posts">
                      All Posts
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link onClick={logoutHandler}>Logout</Nav.Link>
                </Nav>
              ) : (
                <Nav className="justify-content-end">
                  <Nav.Link href="/#/login/">LogIn</Nav.Link>
                </Nav>
              )}
            </Col>
            {currentUser ? <SpeedDialComp /> : null}
          </Navbar>
        </Row>
      </Navbar>
      {/* {error && <Message variant="danger">{error}</Message>}
      {success && <Message variant="success">{"Succeed!"}</Message>} */}
    </header>
  );
}

export default Header;
