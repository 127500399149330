import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import background from "../stars.gif";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getUserDetails } from "../actions/userActions.js";
import { createPost } from "../actions/postActions.js";
import { POST_CREATE_RESET } from "../constants/postConstants";
import { useAuthValue } from "../AuthContext";

const PostsScreen = ({ history }) => {
  const { currentUser } = useAuthValue();
  const [postTitle, setPostTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const [mediaType, setMediaType] = useState("");

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateContent = useSelector((state) => state.userUpdateContent);
  const { success } = userUpdateContent;

  const postCreate = useSelector((state) => state.postCreate);
  const { success: postSuccess, loading: postLoading, post } = postCreate;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!currentUser) {
      history.push("/");
    } else {
      if (!user || success || currentUser.email !== user.email) {
        dispatch(getUserDetails(currentUser.uid));
      } else if (postSuccess) {
        dispatch({ type: POST_CREATE_RESET });
        history.push("/edit_post/" + post.id + "/");
      } else {
        setPostTitle(user.postTitle);
        setPostContent(user.postContent);
      }
    }
  }, [dispatch, history, user, success, postSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createPost({
        id: user.id,
        media_type: mediaType,
        post_title: postTitle,
        post_content: postContent,
      })
    );
  };

  // if (isLoading) {
  //   return <div>Loading ...</div>;
  // }

  return (
    <div className="flex-container">
      <Row className="justify-content-md-center">
        <Col sm={8}>
          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="postTitle">
              <Form.Label>Media Type</Form.Label>
              <Form.Select
                size="lg"
                value={mediaType}
                onChange={(e) => setMediaType(e.target.value)}
              >
                <option value="VIDEO">Video</option>
                <option value="MUSIC">Music</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="postTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="postTitle"
                placeholder="Add Title"
                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="postContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                required
                type="postContent"
                placeholder="Add Content"
                value={postContent}
                as="textarea"
                rows={5}
                onChange={(e) => setPostContent(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default PostsScreen;
