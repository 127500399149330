import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Face5Icon from "@mui/icons-material/Face5";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import InfoIcon from "@mui/icons-material/Info";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth } from "../firebaseConfig";
import { useDispatch } from "react-redux";
import { logout } from "../actions/userActions";
import { useHistory } from "react-router-dom";

const SpeedDialComp = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logoutHandler = (action) => {
    console.log("action: ", action);
    if (action.name !== "Logout") {
      history.push(action.path);
    } else {
      auth.signOut();
      console.log("dispatch: logout");
      dispatch(logout());
      console.log("logged out.............................");
    }
  };
  const actions = [
    { icon: <LogoutIcon />, name: "Logout", path: "logoutHandler" },
    { icon: <Face5Icon />, name: "MyProfile", path: "/update_profile" },
    { icon: <InfoIcon />, name: "MyInfo", path: "/update_personal" },
    {
      icon: <FeaturedPlayListIcon />,
      name: "Featured Content",
      path: "/update_content",
    },
    { icon: <PostAddIcon />, name: "New Post", path: "/create_post" },
  ];

  return (
    <SpeedDial
      className="speed-dial"
      ariaLabel="SpeedDial basic example"
      sx={{ position: "fixed", bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => logoutHandler(action)}
        />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialComp;
